<template>
  <router-view/>
</template>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,400&display=swap');

  html, body,
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: 62.5%;
  }

  * {
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    line-height: 3rem;
  }

  h1 {
    font-size: 3rem;
  }
</style>
