<template>
  <main class="home-page">
    <h1>TS Collaboration Proposal</h1>

    <section>
      <p>
        Salut Stefan, urmatorul document este creat in cheia TS 500. Am inteles foarte clar ca
        tot efortul pe care il depunem trebuie sa fie intr-un singur scop, (un singur target) si anume de a ajunge la o
        echipa de x developeri, in cazul actual aceasta cifra fiind stabilita la 500.
        <br>
        <br>
        Doresc prin parteneriatul nostru sa sprijin acest scop, si iata strategia pe care o propun in acest sens:
      </p>

      <ul>
        <li>sa iti prezint ce imi doresc din parteneriatul nostru</li>
        <li>sa listez lucrurile bune ce exista in companie</li>
        <li>sa subliniez cateva din lucrurile ce necesita optimizare</li>
        <li>sa conturez rolul ce il propun ca solutie pentru indeplinirea scopului TS 500</li>
      </ul>

      <p>
        Motivul pentru care doresc sa continui sa lucram impreuna este credinta mea ca exista oameni de calitate in
        companie de la care pot invata si eu, si fundatia pe care s-a creat TS are potential de crestere.
        <br><br>

        Iata ce imi doresc in principal din parteneriatul nostru:
      </p>

      <ul>
        <li>sa fim transparenti in ceea ce priveste taskurile si activitatile in care o sa fiu implicat, sau la care o
          sa
          lucrez; cu alte cuvinte sa negociem in limita bunului simt profesional cum si ce sa facem impreuna, ca sa pot
          avea un input cat mai bun prin intelegerea a ceea ce urmeaza sa dezvoltam
        </li>
        <li>munca ce o produc sa fie cuantificabila, masurabila si verificabila</li>
        <li>sa am o activitate coerenta, adica less task switching si cereri paralele</li>
        <li>sa fiu util colegilor si sa le ridic moralul atunci cand este nevoie</li>
        <li>sa lucrez intr-un mediu in care pilonii parteneriatului sa fie de ordin etic profesional si bazat pe
          respectul uman reciproc si pe intelegere la un nivel profund
        </li>
        <li>sa fiu provocat sa invat lucruri noi</li>
        <li>sa sarbatorim succesele mici, medii si mari</li>
      </ul>

      <p>
        Aici las si o lista cu ce imi place la TS:
      </p>

      <ul>
        <li>gradul de transparenta si colegialitate e suficient ca sa ma simt confortabil si in siguranta la munca</li>
        <li>am colegi pe care ii admir si simt ca invat muncind cu ei</li>
        <li>exista o colegialitate placuta in grupurile in care sunt implicat</li>
        <li>exista dorinta de imbunatatire tehnologica prin introducerea standardelor profesionale</li>
        <li>cultura organizationala e axata si pe membri (team buildings, party’s, etc.)</li>
        <li>exista deschidere spre orizonturi noi</li>
        <li>grad de adaptabilitate bun la noile schimbari</li>
      </ul>

      <p>
        Iata si cateva lucruri ce consider ca trebuie optimizate:

      </p>

      <ul>
        <li>transparenta intre departamente</li>
        <li>uneltele digitale folosite in procesele noastre zilnice</li>
        <li>awareness-ul general al colegilor pentru a trage in aceeasi directie</li>
      </ul>

      <p>
        In baza acestor detalii, rolul conturat in jurul lor ar fi de <b>Process optimizer manager / engineer.</b>
        <br>
        <br>
        Adica, in concret, am concluzionat ca e nevoie de un om central care sa observe comunicarea intre noi si
        procesele folosite, care sa determine unde si ce trebuie imbunatatit, venind cu solutii potrivite. De multe ori
        este doar nevoie de un meet pentru a rezolva o problema, alteori de construirea unei solutii mai ample.

        In acest rol vor intra activitatile de:
      </p>

      <ul>
        <li>company data collection (human data and process data)</li>
        <li>standard implementation strategy and execution (the Golden standards)</li>
        <li>improving developers skills (Learning engineer)</li>
        <li>improving overall communication between departments</li>
        <li>conduct leadership programs</li>
        <li>research different topics which tend to become bad patterns</li>
      </ul>

      <p>

        Într-o propozitie, va propun un parteneriat pentru a ajuta TS sa-si atinga obiectivul mai rapid,
        considerand ca rolul de manager al optimizarii proceselor reprezinta liantul necesar pentru acest scop.
        <br>
      </p>
    </section>

    <section class="vision-strategy">
      <h2>Viziune si strategie</h2>

      <p>
        Asa cum am discutat in ultima intalnire, iata un exemplu de viziune si strategie ce consider a fi usor de
        inteles si de urmat de catre membrii companiei.
      </p>

      <div>
        <h3>Vision</h3>
        <i>To become a globally recognized IT outsourcing company, renowned for delivering high-quality software
          solutions through a talented team of 500 developers, known for their expertise in TDD, transparent
          communication, and customer-centric approach.</i>
      </div>

      <div>
        <h3>Strategy</h3>
        <div v-for="{title, list} in strategies" key="item.title">
          <h4>{{ title }}</h4>
          <ul>
            <li v-for="item in list" key="item">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>

      <p>
        Regularly assess your progress and make necessary adjustments to stay on course towards realizing your vision.
      </p>
    </section>
  </main>
</template>

<script setup>
  const strategies = [
    { title: 'Cultural transformation', list: [
          'maintain a culture of learning on demand, collaboration, and innovation. Encourage employees to embrace TDD and transparent communication as core values',
          'conduct regular training and workshops on TDD, Agile methodologies, and effective communication techniques'
      ] },
    { title: 'Employee Development', list: [
          `create a career development path that includes clear progression opportunities for developers to grow within the company`,
          `offer ongoing training programs, certifications, and mentorship to help employees master TDD and communication skills`,
          `encourage team members to collaborate on projects and share knowledge`
      ] },
    { title: 'Client Relationships', list: [
        `maintain open and transparent communication channels with clients throughout project lifecycles`,
        `seek continuous feedback and prioritize customer satisfaction`,
        `showcase your company's commitment to TDD and communication excellence as a unique selling point to attract and retain clients`
      ] },
    { title: 'Quality Assurance and Continuous Improvement', list: [
        `establish a culture of quality assurance and continuous improvement, emphasizing automated testing, code
        reviews, and regular retrospectives`
      ] },
    { title: 'Community Engagement and Thought Leadership', list: [`encourage team members to participate in industry conferences, webinars, and open-source contributions to
        establish your company as a aspiring leader in TDD and software development`] },
    { title: 'Mentorship and Leadership Development', list: [`identify and help future leaders from within your organization to ensure effective management and guidance
        as the company expands`, `encourage experienced team members to mentor junior developers and share their expertise`] },
    { title: 'Monitoring and Adaptation', list: [`regularly monitor progress toward your vision and make adjustments as needed`, `solicit feedback from employees, clients, and stakeholders to ensure alignment with your company's values
        and goals`] }
  ]
</script>

<style lang="scss">
  .home-page {
    @media only screen and (min-width: 0) {
      max-width: 760px;
      margin: 0 auto;
      padding: 24px 16px 200px;
      text-align: left;
      font-size: 2rem;

      h1 {
        text-align: center;
      }

      .vision-strategy {
        h2 {
          margin-top: 60px;
          font-size: 3.2rem;
        }
        h3 {
          margin-bottom: 6px;
        }
        h4 {
          margin-top: 8px;
          margin-bottom: 0;
        }
        p {
          margin-bottom: 0;
          font-size: 1.8rem;
        }
        ul {
          margin-top: 0!important;
          li {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
</style>
